import React, { useEffect } from "react";
import saima from "../../assets/images/saima.jpg";
import news1 from "../../assets/images/news1.jpg";
import news2 from "../../assets/images/news2.jpg";

const SaimaProfile = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <img
                className="img-fluid rounded-circle"
                src={saima}
                style={{ padding: "0px 90px" }}
                alt="saima"
              ></img>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
                Saima Shaheen
              </h1>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row px-3" style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bolder", fontSize: "18px" }}>
              <br />
              <br />
              Welcome to Suraya Beauty Lounge, a place where every woman is a
              star! I'm Saima Shaheen, a passionate, skilled licensed Master
              Esthetician specializes in providing result oriented skincare
              treatments for women at any age. I enjoy helping my clients
              achieve their skincare goals and enhance their beauty by tailoring
              every treatment to their specific needs. I have 18 years of
              experience as a senior Master Esthetician, 12 years of which I
              served as a spa director at a privately owned Day Spa. I'm
              certified in many skincare modalities including but not limited
              to: facials, Microdermabrasion, Dermaplaning, Chemical peels, Brow
              contouring, and Hair removal. I'm also certified in advanced
              Manual Lymph Drainage for the head and neck, Cupping, Reflexology
              and Skin Fitness. While quarantined due to Covid-19, I took the
              time to earn more certifications and I can proudly say, I’m a
              certified infection preventionist.
              <br />
              <br />
              <br />
              After successfully helping many clients turn around their
              problematic skin, I launched Suraya Beauty Lounge. One of my goals
              is to alter the current misconceptions about skincare and to
              positively influence the way females take care of their skin
              health and maintain their beauty. At Suraya beauty, our clients
              learn that caring for the skin is a ritual they should be
              practicing daily and celebrate once a month with proper
              professional care.
              <br />
              <br />
              <br />
              Twenty years ago, I moved to Reston, Virginia from Amman- Jordan.
              Before the move, I obtained my bachelor’s degree in journalism and
              public relations and worked as a Journalist at a daily newspaper
              for a year. As much as I loved journalism, having kids made me
              rethink my priorities and my career path. During college summer
              breaks, my mom (Suraya) encouraged me to take courses in essential
              life skills. One of these courses was in esthetics. When the
              career switching opportunity presented itself, I turned to my
              passion for skincare. I decided to go back to school and take
              comprehensive certification in esthetics from the French Academy
              for Beauty Arts & Techniques in Amman, Jordan and started my
              career.
              <br />
              <br />
              <br />
              I'm a mother to two sons, 19 and 17 years old, who both run the
              other direction when I approach them with my mischievous smile
              that announces my desire to pick on their skin. I am an avid
              reader and love watching movies and Netflix programs with my
              family and this spring was the first spring I passed without
              visiting a baby goats farm due to the COVID-19 stay at home order.
            </p>
          </div>
          <div className="row mt-5" style={{textAlign:"center"}}>
          <div className="col-md-2 my-2"></div>
            <div className="col-md-4 my-2">
            <img
                src={news1}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
            <img
                src={news2}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-2 my-2"></div>

          </div>
        </div>
      </div>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default SaimaProfile;
