import React, { useEffect } from "react";
import giftcard from "../../assets/images/gift.jpg";

const GiftCards = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${giftcard})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.6)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container px-5">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Gift Certificates / Cards
            </h1>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              Make your gift giving a magical experience that combines
              pampering, beauty and wellbeing in one place, At Suraya Beauty
              Lounge, we are committed to provide top-notch services to all our
              guests.
              <br />
              <br />
              We offer a variety of gift certificate options that can be easily
              customized to pamper someone special or say thank you in a
              memorable way.
              <br />
              <br />
              At Suraya Beauty Lounge, gift giving is made simple. Gift
              certificates are available at any price increment and for any
              service. Your gift card can be email, mailed or picked up wrapped
              and ready to impress.
            </p>
          </div>
          <div className="row text-center py-5">
            <button
              className=" btn btn-lg"
              style={{
                width: "250px",
                backgroundColor: "#e4d6e4",
                borderColor: "#690b93",
                color: "#690b93",
                margin: "auto",
              }}
              onClick={() =>
                window.open("https://squareup.com/gift/ML4FTACCSXF2H/order")
              }
            >
              Get a Gift Card
            </button>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Terms and Conditions
            </h1>
          </div>
          <div className="row pt-5">
            <ul
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              <li>
                All gift certificates/cards expire after 1 year from the date of
                purchase.
              </li>
              <li>
                A 10% Maintenance, dormancy or service fee per year will apply
                to the remaining balances on expired gift certificates/cards
                starting on the 13th month from the original date of purchase.
              </li>
              <li>
                Promotional gift certificates/cards, or already discounted ones,
                cannot be combined on already discounted monthly promotions,
                rewards, Groupon, BOGO (buy one get one) or any similar
                offerings. In addition, promotional gift vouchers will not have
                an extendable expiration date and might be subject to additional
                restrictions.
              </li>
              <li>
                Gift certificates/cards can be used on the purchase of retail
                products . The value of the certificate can be used toward other
                services than the one intended on the original gift certificate.{" "}
              </li>
              <li>
                Keep your Gift Card safe. Gift Cards that are lost or stolen
                cannot be replaced and Suraya Beauty Lounge Inc. shall not be
                responsible for lost or stolen cards.
              </li>
              <li>
                Gift Certificates are not redeemable for cash and cannot be used
                to pay monthly fees.{" "}
              </li>
              <li>Gift Certificate sales are final and non-refundable. </li>
              <li>
                If the service listed at the gift certificate is no longer
                offered at the lounge, seasonal promotions, the remaining
                financial balance on the original gift certificate will be
                transferred to be used toward any alternative service equal in
                price or more to the service listed in the original gift
                certificate. With the client understanding that they are
                responsible to cover any changes of price or upgrade fees.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default GiftCards;
