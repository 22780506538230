import React from "react";

const Differences = () => {
  return (
    <>
      <div
        className="modal fade"
        id="differences"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="differences"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="differencesTitle"
                style={{ color: "black", fontWeight: "bolder" }}
              >
                Our Differences
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                <li className="list-group-item">
                  1- All our treatments start with consultation, skin analysis
                  and full customization to your skin’s specific needs with
                  every visit .
                </li>
                <li className="list-group-item">
                  2- You don’t know which facial to choose? No problem: book The
                  Tailored Signature Facial and we will guide you to the perfect
                  treatment based on your skin analysis results, budget and your
                  upcoming life events.
                </li>
                <li className="list-group-item">
                  3- We don’t judge your home care regimen. And we don’t try to
                  push retail products on you just to make a sale. We will make
                  sure that you are using your products correctly and provide
                  you with the necessary knowledge and the product choices that
                  will bridge the gap in your current care regimen.
                </li>
                <li className="list-group-item">
                  4- We offer a VIP saving program. For more details, look for
                  The Stars Ritual Program section.
                </li>
                <li className="list-group-item">
                  5- All our products are paraben free and environmentally
                  friendly including our hospital grade disinfectant.
                </li>
                <li className="list-group-item">
                  6- We are the first and the only beauty spa in Northern
                  Virginia to offer a mobile spa service for terminally ill
                  females. For more details, look for The Alleviation Care
                  Initiative section.
                </li>
                <li className="list-group-item">
                  7- We are certified in infection prevention and control
                  through Rejuvenate: an environmental friendly hospital grade
                  disinfectant.
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Differences;
