import React, { useEffect } from "react";
import touch from "../../assets/images/touch.jpg";

const TouchTherapy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${touch})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.3)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Touch Therapy & Full body Massage Services
            </h1>
          </div>
          <div className="row">
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Wholistic Head Massage
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $90.00, 60 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $60.00, 30 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Whether you suffer from seasonal allergies, sinus, TMJ,
                    Migraine or tension headache, this extended blend of deeply
                    relaxing modalities and aromatherapy, is designed to combat
                    stress and reduce the unwanted symptoms. The treatment will
                    boost circulation and cover the scalp, face, neck, and
                    shoulders.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Wholistic Facelift massage
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $90.00, 60 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $60.00, 30 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Give your skin's natural anti-aging boost with this enhanced
                    blend of therapeutic modalities - including micro-current,
                    skin fitness, facial cupping, pressure point and manual
                    lymph drainage- to target the appearance of fine lines and
                    wrinkles.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Manual Lymph Drainage <br />( head & neck)
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $90.00, 60 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $60.00, 30 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    A Therapeutic massage that uses uniformed rhythmic pulsating
                    motion to encourage the lymphatic fluid circulation in the
                    body. The gentle strokes are done carefully to promote a
                    hypnotizing effect on the nervous system and boost the
                    lymphatic vessels function to nourish the skin and eliminate
                    toxins. Great after plastic and dental surgery, for scars
                    healing and for skin edema.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Therapeutic Stretching
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $25.00, 15 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $50.00, 25 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Enhance your joints flexibility and your muscles oxygenation
                    and elasticity by adding this amazing service to any massage
                    or book solo . Suitable for any conditions specially
                    athletes, fibromyalgia , and arthritis sufferers.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Cupping
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $60.00, 30 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Using Modern Silicone cups, a negative pressure is created
                    and the cups are placed in a stationary way or gently glided
                    over the skin causing a deeply relaxing sensation. The
                    treatment will result in improved circulation and an instant
                    relief to the fatigued body or sore body part.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Reflexology
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $90.00, 60 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $60.00, 30 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Also known as zone therapy, this ancient technique is
                    performed by applying pressure to key points on the feet,
                    hands, and ears that will resonate with other parts of the
                    body. It is believed that reflexology balances the energy
                    over the body and has a detoxifying effect on its organs.
                    Not suitable for expecting mothers.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Swedish Massage
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $100.00, 55 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $125.00, 85 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    This massage uses traditional techniques of stroking,
                    kneading, alignment, and tapping. You'll experience improved
                    blood circulation, reduced stress and eased muscle tension,
                    which produces a tranquilizing effect on the nervous system.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Tailored Custom Massage
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $115.00, 55 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $140.00, 85 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Our therapist will evaluate your specific needs and custom a
                    blend of therapeutic modalities to address your specific
                    concerns. Some of the combination modalities that will be
                    used during this service includes but not limited to: Deep
                    Tissue, Mayofacial Releas, Cupping , and Trigger point.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Stone Therapy Massage
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $125.00, 55 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $150.00, 85 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Stone Therapy Massage Using rounded Lava Stones , this
                    massage provides the ultimate relaxing experience. Heat from
                    the stones penetrates deep into the muscles, improving
                    circulation and easing muscle tension and soreness.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default TouchTherapy;
