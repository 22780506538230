import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "./Components/Home/Navbar";
import Home from "./Components/Home/Home";
import SkinCare from "./Components/Services/SkinCare";
import TouchTherapy from "./Components/Services/TouchTherapy";
import SaimaProfile from "./Components/Home/SaimaProfile";
import OurStory from "./Components/Home/OurStory";
import GroomingLounge from "./Components/Services/GroomingLounge";
import AlleviationCare from "./Components/Services/AlleviationCare";
import InfectionPrevention from "./Components/Home/InfectionPrevention";
import PermanentMakeup from "./Components/Services/PermanentMakeup";
import BeforeAndAfter from "./Components/Services/BeforeAndAfter";
import PermQA from "./Components/QA/PermQA";
import GiftCards from "./Components/Home/GiftCards";
import PopUpItem from "./Components/PopUpItem";

function App() {

  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/skincare" component={SkinCare} />
        <Route path="/touchtherapy" component={TouchTherapy} />
        <Route path="/me" component={SaimaProfile} />
        <Route path="/us" component={OurStory} />
        <Route path="/groominglounge" component={GroomingLounge} />
        <Route path="/alleviationcare" component={AlleviationCare} />
        <Route path="/permanentmakeup" component={PermanentMakeup} />
        <Route path="/infectionprevention" component={InfectionPrevention} />
        <Route path="/beforeandafter" component={BeforeAndAfter} />
        <Route path="/permqa" component={PermQA} />
        <Route path="/giftcard" component={GiftCards} />
        <Redirect to="/home" />
      </Switch>
      <div>
        <button
          className="btn btn-lg book-now-floating"
          onClick={() =>
            window.open(
              "https://www.schedulicity.com/scheduling/SBLBXW/services"
            )
          }
        >
          Book Now
        </button>
      </div>
      <PopUpItem/>
    </div>
  );
}

export default App;
