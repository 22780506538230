import React from "react";
import saima from "../../assets/images/saima.jpg";
import fatima from "../../assets/images/fatima.jpg";
import haily from "../../assets/images/haily.jpeg";
import Muna from "../../assets/images/Muna.jpg";
import roxy from "../../assets/images/roxy.jpeg";
import Thalia from "../../assets/images/Thalia.jpg";
import Hong from "../../assets/images/Hong.jpg";
import { Link } from "react-router-dom";

const Staff = () => {
  return (
    <>
      <section
        className="page-section"
        id="staff"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, white)" }}
      >
        <div className="container">
          <h2 className="text-center mt-0">Our Staff</h2>
          <hr className="divider my-4" />
          <div className="gtco-testimonials">
            <div className="row owl-carousel owl-carousel1 owl-theme">
              <div className="col-md-3"></div>
              <div className="col-md-6 my-2 text-center">
                <Link to="/me" style={{ textDecoration: "none" }}>
                  <img
                    src={saima}
                    alt=""
                    style={{
                      width: "200px",
                      maxWidth: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      margin: "15px auto 0",
                      boxShadow: "0 8px 20px -8px #690b93",
                    }}
                  />
                  <h2
                    className="mt-2"
                    style={{
                      color: "#690b93",
                      fontWeight: "bold",
                      background: "-webkit-linear-gradient(#690b93, #690b93)",
                      backgroundClip: "border-box",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      marginBottom: "0px",
                    }}
                  >
                    Osaima Shaheen
                  </h2>

                  <h2
                    className=""
                    style={{
                      color: "#690b93",
                      background: "-webkit-linear-gradient(#690b93, #690b93)",
                      backgroundClip: "border-box",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      marginBottom: "0px",
                      fontSize: "20px",
                    }}
                  >
                    Master Esthetician
                    <br />
                    Show more...
                  </h2>
                </Link>
                {/* <div className="card h-100 text-center">
                  <img
                    className="card-img-top"
                    src={saima}
                    alt=""
                    style={{
                      width: "200px",
                      maxWidth: "200px",
                      height: "200px",
                    }}
                  />
                  <div className="card-body">
                    <h1
                      style={{
                        color: "#690b93",
                        fontWeight: "bold",
                        background: "-webkit-linear-gradient(white, #690b93)",
                        backgroundClip: "border-box",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        marginBottom: "70px",
                      }}
                    >
                      Osaima Shaheen
                    </h1>
                    <Link to="/me" style={{ textDecoration: "none" }}>
                      <h2 className="card-text btn-shine mt-4">
                        Master esthetician
                      </h2>
                    </Link>
                  </div>
                </div> */}
              </div>
              <div className="col-md-3"></div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#fatimaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={fatima}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Fatimah Darab
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Massage Therapist
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#hailyModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={haily}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Hailey Simpson
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Massage Therapist
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#munaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Muna}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Muna Asmar
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Master Esthetician
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#roxyModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={roxy}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Roxy Garcia
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Permanent Makeup Artist
                  <br />
                  Show more...
                </h2>
              </div>
              <div className="col-md-3 text-center my-2"></div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#thaliaModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Thalia}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Thalia Cobb
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Esthetician / Lash Specialist
                  <br />
                  Show more...
                </h2>
              </div>
              <div
                className="col-md-3 text-center my-2"
                data-toggle="modal"
                data-target="#hongModal"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={Hong}
                  alt=""
                  style={{
                    width: "150px",
                    maxWidth: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                    boxShadow: "0 8px 20px -8px #690b93",
                  }}
                />
                <h2
                  className="mt-2"
                  style={{
                    color: "#690b93",
                    fontWeight: "bold",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  Hong Sun
                </h2>

                <h2
                  className=""
                  style={{
                    color: "#690b93",
                    background: "-webkit-linear-gradient(#690b93, #690b93)",
                    backgroundClip: "border-box",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: "0px",
                    fontSize: "16px",
                  }}
                >
                  Massage Therapist
                  <br />
                  Show more...
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* hailyModal */}
      <div
        className="modal fade"
        id="hailyModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={haily}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Hailey Simpson
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Hailey, a graduate of the Northern Virginia School of
                Therapeutic Massage, is a Licensed Massage Therapist
                specializing in therapeutic massage and deep tissue work. With a
                calm and grounded approach she is able to truly tap into her
                clients body and listen to its needs.
                <br />
                <br />
                Passionate about holistic healing and wellness, Hailey is
                dedicated to expanding her knowledge in natural healing methods.
                She is currently working towards learning energy focused
                techniques to incorporate into her practice to further support
                clients in healing their mind, body, and spirit. Additionally,
                Hailey is dedicated to her own personal growth and healing,
                recognizing self-care is essential in her role with assisting
                others to heal.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end hailyModal */}
      {/* fatimaModal */}
      <div
        className="modal fade"
        id="fatimaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={fatima}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Fatimah Darab
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Fatimah Darab is a licensed massage therapist who is skilled in
                several massage modalities. Fatimah is interested in all healing
                arts. As a a Yoga instructor, she demonstrates a thorough
                understanding of the body’s mobility. She pays special attention
                to the clients concerns during her consultation.
                <br />
                <br />
                And she customize her techniques to each individual specific
                needs. Since she joined our team last May, she’s been in high
                demand with great following and regular clientele. Modalities:
                Swedish massage, Prenatal, Deep tissue.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end fatimaModal */}
      {/* munaModal */}
      <div
        className="modal fade"
        id="munaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={Muna}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Muna Asmar
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                This beautiful Gem joined our team last month and she is being
                nothing but a breath of air. Although Muna obtained her master
                esthetician license few months ago. She has long experience in
                eastern medicine , acupuncture and cupping that she practiced
                while she resides overseas. Muna loves anti aging facials ,
                dermaplanning and skilled at many other beauty techniques such
                as threading.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end munaModal */}
      {/* roxyModal */}
      <div
        className="modal fade"
        id="roxyModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={roxy}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Roxy Garcia
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Roxy always had a great passion for beauty and artistry. Her
                clear talent in capturing symmetry and right skin tones in her
                drawing and art , leaded her to the magical world of permanent
                make up. After graduating from Lillique Training Institute in
                2020 and became a certified, licensed Permanent Make artist,
                she’s becoming the go to person in her surrounding for her great
                technique and warm personality. As she grows in her career Roxy
                , would love to expand her expertise to include lip blushing,
                liner, nano strokes , and help women gain the confidence they
                need to appreciate their natural beauty.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end roxyModal */}
      {/* thaliaModal */}
      <div
        className="modal fade"
        id="thaliaModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={Thalia}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Thalia Cobb
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                The GOAT of lash artist and the personal lash expert for Miss
                Virginia, Miss Northern Virginia and Miss Arlington. Thalia is a
                talented Esthetician, Esthetic instructor who is experienced and
                specialized on all kind beauty services in addition of being the
                most requested lash artist in the area. Her passion for lashes
                made her perfect all lash styles and application. Thalia focuses
                on providing an ultimate lasting results that will compliment
                the client natural look and boost her beautiful eyes.x
                <br />
                <br />
                Lash stylists are also trained to properly measure lashes to
                choose appropriate lengths and calculate the weight the natural
                lash can hold. This preserves the health of the natural lash,
                meaning there will not be breakage or loss of natural lashes.
                Each client is also taught how to clean and care for their
                beautiful new lashes to maximize longevity and ensure your
                lashes stay looking their best! As an esthetician and Lashes
                trained stylist, cleanliness and sterility practices are also
                very important to Thalia as she make sure to follow all needed
                hygiene protocols to , reduce the risk of contamination and
                infection for each client. Clients love her waxing, lash lifting
                and brow lamination as well.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end thaliaModal */}
      {/* hongModal */}
      <div
        className="modal fade"
        id="hongModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={Hong}
                alt=""
                style={{
                  width: "80px",
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  boxShadow: "0 8px 20px -8px #690b93",
                }}
              />
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                style={{
                  color: "#690b93",
                  fontWeight: "bold",
                  background: "-webkit-linear-gradient(#690b93, #690b93)",
                  backgroundClip: "border-box",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: "0px",
                  fontSize: "20px",
                  alignSelf: "center",
                  margin: "0px 15px",
                }}
              >
                Hong Sun
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={{
                  paddingBottom: "0px",
                  fontSize: "14px",
                  textAlign: "start",
                }}
              >
                Hong has over 20 yrs of massage experience and she is well known
                for her therapeutic approach and ability to customize her
                modalities to help her clients achieve the best relaxation and
                pain relief . She specialized in myofacial release, deep tissue
                , stone therapy , prenatal and more .
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* end hongModal */}
    </>
  );
};

export default Staff;
