import React, { useEffect } from "react";
import grooming from "../../assets/images/grooming.jpg";
const GroomingLounge = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${grooming})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.4)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              The Grooming Lounge
            </h1>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h4
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bold",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              We offer waxing for the full body,
              <br /> Threading for the face and neck and Sugaring for the face,
              underarm and Brazilian bikini
            </h4>
          </div>
          <div className="row pt-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <table className="table table-striped table-dark">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Service</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Brow Shaping </td>
                    <td>$15.00</td>
                  </tr>

                  <tr>
                    <th scope="row">2</th>
                    <td>Lip</td>
                    <td>$11.00</td>
                  </tr>

                  <tr>
                    <th scope="row">3</th>
                    <td>Chin</td>
                    <td>$15.00</td>
                  </tr>

                  <tr>
                    <th scope="row">4</th>
                    <td>Full face</td>
                    <td>$55.00</td>
                  </tr>

                  <tr>
                    <th scope="row">5</th>
                    <td>Under arm</td>
                    <td>$25.00</td>
                  </tr>

                  <tr>
                    <th scope="row">6</th>
                    <td>Full arm Wax</td>
                    <td>$35.00</td>
                  </tr>

                  <tr>
                    <th scope="row">7</th>
                    <td>High bikini</td>
                    <td>$45.00</td>
                  </tr>

                  <tr>
                    <th scope="row">8</th>
                    <td>Brazilian</td>
                    <td>$65.00</td>
                  </tr>

                  <tr>
                    <th scope="row">9</th>
                    <td>Half legs</td>
                    <td>$45.00</td>
                  </tr>

                  <tr>
                    <th scope="row">10</th>
                    <td>Full legs</td>
                    <td>$80.00</td>
                  </tr>

                  <tr>
                    <th scope="row">11</th>
                    <td>Brow Tint</td>
                    <td>$15.00</td>
                  </tr>

                  <tr>
                    <th scope="row">12</th>
                    <td>Lash Tint</td>
                    <td>$25.00</td>
                  </tr>

                  <tr>
                    <th scope="row">13</th>
                    <td>Brightening Anti-Aging Eye Treatment</td>
                    <td>$30.00</td>
                  </tr>

                  <tr>
                    <th scope="row">14</th>
                    <td>Softening lip treatment</td>
                    <td>$20.00</td>
                  </tr>
                  <tr>
                    <th scope="row">15</th>
                    <td>Brow Lamination</td>
                    <td>$85.00</td>
                  </tr>
                  <tr>
                    <th scope="row">16</th>
                    <td>Lash Lifting</td>
                    <td>$85.00</td>
                  </tr>
                  <tr>
                    <th scope="row">17</th>
                    <td>Brow lamination and Lash lifting</td>
                    <td>$130.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default GroomingLounge;
