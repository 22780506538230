import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

const PopUpItem = () => {
  const [showFinanceModal, setShowFinanceModal] = useState(true);
  const [isWomenOnly, setisWomenOnly] = useState(true);

  const onCloseIconClick = () => {
    if(!isWomenOnly)
      setShowFinanceModal(false);
    else
      setisWomenOnly(false);
  };

  return (
    <>
      {showFinanceModal && (
        <div className="financeModal">
          <div className="Fintitle">
          {!isWomenOnly && (
            <h5 style={{ fontWeight: "bold" }}>
                We finance Permanent makeup
            </h5>
          )}
          {isWomenOnly && (
            <h5 style={{ fontWeight: "bold" ,fontSize:"24px",textTransform:"uppercase"}}>
            Attention !!!
            </h5>
          )}
            <div className="CloseIcon" onClick={onCloseIconClick}>
              <Icon
                path={mdiCloseCircle}
                title="User Profile"
                size={1}
                horizontal
                vertical
                rotate={90}
                color="red"
                spin
              />
            </div>
          </div>
          {!isWomenOnly && (
            <div className="Finbody">
              Finally, Permanent makeup is affordable
              <br />
              Contact us to know more,
              <br />
              <a href="mailto:contact@inquiries@surayabeauty.com">
                inquiries@surayabeauty.com
              </a>
              <br />
              1-571-249-2969
            </div>
          )}
          {isWomenOnly && (
            <div
              className="Finbody"
              style={{ fontSize: "24px", lineHeight: "30px" , marginTop:"-10px",textTransform:"uppercase"}}
            > 
              This facility provides services for <span style={{color:"red",textTransform:"uppercase"}}>female clients only</span>.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PopUpItem;
