import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions";
import logo from "../../assets/images/home.jpg";
import Differences from "./Differences";
import Policies from "./Policies";
const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <img
            className="navbar-brand"
            src={logo}
            style={{ scale: "1.5" }}
            alt="Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTop"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTop">
          <ul className="navbar-nav ml-4 mr-auto pt-2 mt-lg-0">
            <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <span className="nav-link">Home</span>
              </li>
            </Link>
            <Link to="/us" style={{ textDecoration: "none", color: "black" }}>
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <span className="nav-link">Our Story</span>
              </li>
            </Link>
            <Link to="/me" style={{ textDecoration: "none", color: "black" }}>
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <span className="nav-link">About Saima</span>
              </li>
            </Link>
            <Link to="/giftcard" style={{ textDecoration: "none", color: "black" }}>
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <span className="nav-link">Gift Cards</span>
              </li>
            </Link>
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuServices"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Services
              </span>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuServices"
              >
                <Link
                  to="/skincare"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Skin Care
                  </span>
                </Link>
                <Link
                  to="/groominglounge"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Grooming Lounge
                  </span>
                </Link>
                <Link
                  to="/touchtherapy"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Touch Therapy & Full body Massage
                  </span>
                </Link>
                <Link
                  to="/permanentmakeup"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Permanent Make up
                  </span>
                </Link>
              </div>
            </li>
            <Link
              to="/beforeandafter"
              style={{ textDecoration: "none", color: "black" }}
            >
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarTop"
                aria-controls="navbar"
                aria-expanded="true"
              >
                <span className="nav-link">Before & After</span>
              </li>
            </Link>
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuothers"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Others
              </span>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuothers"
              >
                <Link
                  to="/alleviationcare"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    The Alleviation Care Initiative
                  </span>
                </Link>
                <Link
                  to="/infectionprevention"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Infection Prevention and Control
                  </span>
                </Link>
                <span
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#differences"
                  style={{ cursor: "pointer" }}
                >
                  Our Differences
                </span>
                <span
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#policies"
                  style={{ cursor: "pointer" }}
                >
                  Our Policies
                </span>
              </div>
            </li>
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuothers"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Q & A
              </span>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuothers"
                style={{overflow:"scroll",maxHeight:"400px"}}
              >
                <Link
                  to="/permqa"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Permenant Makup Q&A
                  </span>
                </Link>
                <a
                  href="https://drive.google.com/file/d/1xlgPNhhB6sb5Xd1Q4dWfeXHr8KiBovqW/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Anti Aging Treatments
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1FTYuzFsJ6kFsscImvHMS_R3y48G2W4Su/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Basic Facial
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1AJe74XU9d_XpmAn0xjsY1lPLqSZWEn6u/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Brazillian Waxing
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1cLDZx205KyZTYtl2HQDTG84xPtTFPQyW/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Chemical Peels
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1f8Ra1f5e9kzjknKz240B6c2_ohi12lVD/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Communication A Key to Your Treatment
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1N-IqX3a7OOoWmVeNseZal_PQPONBLSKI/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Exfoliation
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1xUW-41SystBmmWzyrSrCnvo_vk-Lu_zI/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Home Care An Excellent Investment
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1YEVrRDv196zINjfJJA4gU7EaxWkLlVs-/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    LED Therapy
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1o5Qdjhh6nr1NUFnuzmhPNthznJMeWG66/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Manual Lymph Drainage
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1Sqd7_b5VsMg7Cv2rS4b1yS9l6oPXhbao/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Microdermabrasion
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1VnJdTbcusXDE8Nrgk8X7O25WiFsSNzbv/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Organic Skin Care
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1FBJVeFU-_YsAXYUD_i0GXbivE58cTHWL/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Skin of Color
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1wnhRbcidyGDJ0pC55YHtV7WHBzxf_eBx/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    The Sun and Your Skin
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1yaZVFUbFjQiti0V1HYPW72v5ZLtqXbsS/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Treating Acne
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1pUxKLwpyOF5Vp-mRWdu9cdS8JIjC4Wn0/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Treating Rosacea
                  </span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1KI13TRtq2SpY2tN-fP8D6SKedJrarP4l/view?usp=sharing"
                  target="blank"
                >
                  <span
                    className="dropdown-item"
                    data-toggle="collapse"
                    data-target="#navbarTop"
                    aria-controls="navbar"
                    aria-expanded="true"
                  >
                    Waxing
                  </span>
                </a>
              </div>
            </li>
          </ul>
          <div
            className="mx-2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <a
              href="https://www.facebook.com/profile.php?id=100063685173214"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook fa-lg mx-1"></i>
            </a>
            <a
              style={{ color: "#ac2bac" }}
              href="https://instagram.com/saimaskin.2019?igshid=YmMyMTA2M2Y="
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram fa-lg mx-1"></i>
            </a>
          </div>
        </div>
      </nav>
      <Differences />
      <Policies />
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.userState.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
