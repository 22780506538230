import React, { useEffect } from "react";
import skincare from "../../assets/images/skincare.jpg";

const SkinCare = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${skincare})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.2)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Skin Care Services
            </h1>
          </div>
          <div className="row">
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Tailored Signature Facial
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $100.00, 50 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    This deep cleansing facial provides a perfect level of
                    customization to target your specific skin needs. A full
                    skin analysis, extraction, masque, and tailored massage for
                    the face, neck and shoulders are included to restore your
                    skin and lift your spirit. Suitable for all skin types and
                    includes a review of your home care regimen with every
                    session. This facial is our choice for the stars ritual
                    program.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Corrective Skin Fusion
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    $130.00, 50 Minutes{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    A powerful trio of vitamin C peel for exfoliation, a topical
                    blend of a targeted corrective cocktail infusion, and a
                    shower of therapeutic LED Light therapy that will reverse
                    the skin’s damage on a cellular level. The combination of
                    the powerful multiphase treatments is clinically proven to
                    brighten the skin and provide an instant hydration, collagen
                    stimulation and inflammation reductions. This facial does
                    not include extraction{" "}
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Acne Assassin Series
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    $130.00, 50 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    (Series of 4 = $360)
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Crush every single bit of acne, congestion and leftover
                    scarring with this deeply purifying series. Each custom
                    treatment will target a new phase of black head elimination,
                    acne reduction, scar resurfacing and pigments brightening,
                    resulting in a flawless polished skin. #1 choice for NOVA
                    Teens.{" "}
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Oxy Diamonds and Blades:
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $150.00, 50 Minutes{" "}
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $85.00, 25 Minutes{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Combine the benefit of physical exfoliation with an added
                    oxygenating system. Depending on your skin’s particular
                    needs, your treatment will start with a medical grade
                    diamond microdermabrasion to remove dead skin, tighten the
                    pores and reduce scarring. Our dermaplaning technique will
                    be introduced to gently lift off the old skin and the
                    unwanted peach fuzz using a sterile non-invasive surgical
                    blade. Either technique will be followed by Oxygen infusion
                    that will guarantee a younger, smoother and stronger looking
                    skin.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Pro-Design Peel
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    $120.00, 45 Minutes
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Made up with small molecules and higher concentration of
                    nutrients, the Pro-Design Peels are able to penetrate the
                    skin on a cellular level to remove the outer layer of the
                    epidermis and to reverse most imperfections. We offer fruit
                    enzyme peels, Vitamin C peels and Chemical peels. Treatments
                    can be repeated at regular intervals to achieve the desired
                    results.{" "}
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>

            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Clarifying Bacne facial{" "}
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $85.00, 45 Minutes{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    Leave your back acne behind and step into summer with
                    confidence. Whether suffering sporadic blackheads or more
                    aggressive breakouts and discoloration caused by acne
                    scarring, this back facial will purify your skin and reveal
                    a flawless runway ready back and shoulders.{" "}
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default SkinCare;
