import React from "react";
import img1 from "../../assets/images/beforeandafter/img1.jpg";
import img2 from "../../assets/images/beforeandafter/img2.jpg";
import img3 from "../../assets/images/beforeandafter/img3.jpg";
import img4 from "../../assets/images/beforeandafter/img4.jpg";
import img5 from "../../assets/images/beforeandafter/img5.jpg";
import img6 from "../../assets/images/beforeandafter/img6.jpg";
import img7 from "../../assets/images/beforeandafter/img7.jpg";
import img8 from "../../assets/images/beforeandafter/img8.jpg";
import img9 from "../../assets/images/beforeandafter/img9.jpg";

const BeforeAndAfter = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(white, #e4d6e4)",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          paddingTop: "10px",
        }}
      >
        <div className="container" style={{textAlign: "center"}}>
          <div className="row mt-5">
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
                Before & After
              </h2>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 my-2">
              <img
                src={img1}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img4}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img3}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img2}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img5}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img6}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img7}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img8}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-4 my-2">
              <img
                src={img9}
                class="rounded"
                alt="..."
                style={{ height: "300px", width: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeforeAndAfter;
