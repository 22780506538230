import React from "react";

const ContactMe = () => {
  return (
    <>
      <div
        className="modal fade"
        id="contactme"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contactmeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="contactmeLabel"
                style={{ fontWeight: "bold" }}
              >
                Contact Me
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Email:&nbsp;&nbsp;
              <a href="mailto:contact@inquiries@surayabeauty.com">
                inquiries@surayabeauty.com
              </a>
              <br />
              <br />
              Phone number:&nbsp;&nbsp;1-571-249-2969
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMe;
