import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "../Services/ContactMe";

const PermQA = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${alleviation})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.6)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Permanent Makeup Q & A
            </h1>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What is permanent makeup?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              A cosmetic tattoo or micropigmentation, it’s created by using a
              machine or manual tools. Tattoos can mimic eyeliner, lipstick, and
              eyebrows. PMU has a different type of technique that can leave you
              with full brows, tinted lips and enhance the color and shape of
              your facial features.
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What is Microblading?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Semi-permanent makeup which uses a manual hand tool with blade to
              obtain pigmented strokes into the skin to imitative brow hairs.
              This technique is best suited for those who have dry/normal skin
              type, and desire a natural look.
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What is Powder/Microshading (Ombre’)?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Powder or Shading, is created by utilizing a pen machine. Soft
              shades of color are implanted into the skin to achieve the color
              and depth desired. The front of the brows are faded and the color
              progressively becomes darker towards the tail. Ombre heals a
              powdered makeup look. This technique is a perfect option if you
              have oily skin type and minimal/no brow hair.
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              What is Combination?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Combination brows are created by using both techniques
              (Microblading and powder/shading). We decide where the
              microblading hair strokes should be placed and then apply shading
              to the area which lacks hair growth and demand more density or
              depth to create even more brow. This technique is best for clients
              who like microblading,but don't have enough natural hair to blend.
              It is convenient on most skin types,but if you have very oily
              skin, Powder-shading is a better option.
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Who is not a good candidate for PMU?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              Permanent brows makeup is a safe and credible process and
              acceptable for most people. However, if you have any of the
              following conditions, you will not be qualified to receive PMU:
            </p>
            <ul
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              <li aria-level="1">Under the age of 18</li>
              <li aria-level="1">Pregnant or nursing</li>
              <li aria-level="1">
                Bleeding disorders (hemophilia) or clotting
              </li>
              <li aria-level="1">Uncontrolled Diabetes</li>
              <li aria-level="1">History of Keloid or hypertrophic scarring</li>
              <li aria-level="1">A pacemaker or major heart problems</li>
              <li aria-level="1">
                Undiagnosed rashes, open wounds,psoriasis, blisters, sunburn,
                acne or moles on treated area.
              </li>
              <li aria-level="1">On going viral infection or diseases.</li>
              <li aria-level="1">
                Undergoing chemotherapy or have cancer.&nbsp;
              </li>
              <li aria-level="1">
                Have used Accutane within the past 12 months.
              </li>
            </ul>
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              If any of the conditions above apply to you, please check out with
              your doctor before booking an appointment and bring a
              doctor&rsquo;s note with you. Also, if you&rsquo;re unsure whether
              or not you are a good candidate, do not hesitate to get in touch
              by emailing @
              <a href="mailto:contact@inquiries@surayabeauty.com">
                inquiries@surayabeauty.com
              </a>{" "}
              or texting us.&nbsp;
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How do I prepare for my brow session?
            </h3>
          </div>
          <div className="row px-5">
            <ul
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              <li>
                Make sure that you do not have makeup on the treatment area
                prior to your appointment.
              </li>
              <li>
                Do not have them waxed or threaded at least a week before your
                appointment.&nbsp;
              </li>
              <li>Avoid caffeine the day of the procedure.</li>
              <li>Reduce alcohol intake 24 hours before your session.</li>
              <li>
                Do not take Aspirin,Niacin,Advil, Ibuprofen or Vitamin E before
                the session.
              </li>
              <li>
                Avoid extensive sun exposure and tanning 3 weeks prior to
                session.
              </li>
              <li>No Botox around the treatment area 2 weeks before.</li>
              <li>
                Avoid brow tinting, chemical peels, laser treatment and
                microdermabrasion 3 weeks prior.
              </li>
              <li>
                Discontinue any chemical exfoliating and Retin-A one week before
                the procedure.
              </li>
              <li>
                Any facial surgeries should be done at least one year prior to
                the process.
              </li>
            </ul>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Does the procedure hurt and how long does it take for an eyebrow
              session?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
              }}
            >
              {" "}
              As each person’s pain varies, pain level generally varies from 0-3
              out of 10. Most clients feel no pain and some face minimal
              discomfort similar to the sensation of the threading or pick off.
              A topical anesthetic is used to numb the brows before and during
              the process to help keep you comfortable.
              <br />
              <br />
              Some of you may stand up increased sensitivity if you get the
              procedure done before or while you are menstruating. In general,
              we recommend you to follow the pre-procedure carte instructions to
              help ensure perfect healed results.
              <br />
              <br />
              PMU session is approximately 3 hours for the first session. The
              following touch up is going to take 2 hours maximum. We start with
              the consultation about the desired shape and color, followed by
              numbing the brows area, then we ask you to fill up the consent
              form. Once the area is numb and the design is approved we begin
              the process.
            </p>
          </div>
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h3
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How long does it take for healing?
            </h3>
          </div>
          <div className="row px-5">
            <p
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              In general, the process takes 7-10 days for the brows to complete
              the peeling part of the healing. The color will keep going to
              darken over the following 2 weeks . The touch up is recommended
              after 4-6 weeks from the initial appointment.
            </p>
          </div>
        </div>
      </div>
      <ContactMe />
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default PermQA;
