import React, { useEffect } from "react";
import alleviation from "../../assets/images/alleviation.jpg";
import ContactMe from "./ContactMe";

const PermanentMakeup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${alleviation})`,
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          paddingBottom: "50px",
          backgroundColor: "rgba(0,0,0,0.6)",
          backgroundBlendMode: "soft-light",
        }}
      >
        <div className="container">
          <div
            className="row text-center pt-4"
            style={{ justifyContent: "center" }}
          >
            <h1
              className="text-center"
              style={{
                color: "#690b93",
                fontWeight: "bolder",
                background: "-webkit-linear-gradient(white, #e4d6e4)",
                backgroundClip: "border-box",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Permanent Makeup
            </h1>
          </div>
          <div className="row">
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Microshading{" "}
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $495.00, 3 hours
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    A semi-permanent makeup which uses a manual hand tool with
                    blade to obtain pigmented strokes into the skin that mimic
                    the natural brow hairs. This technique is best suited for
                    those who desire undetected natural look and have dry/normal
                    skin type or clients with thinning or patchy brows due to
                    variety of reasons such as illness aging , extreme previous
                    plucking . The service price includes consultation ,
                    detailed mapping , numbing and after care kit. Follow up
                    touch up session must be booked after 6-8 weeks. The service
                    is illegible for financing . The price doesn’t include the
                    touch up session cost.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Microblading{" "}
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $495.00, 3 hours
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    This technique is not a full tattoo or full strokes of
                    pigment. Micro-shading is a technique that uses pin-like
                    dots of pigmentation to achieve a powdered eyebrow look.
                    This technique is great for clients with oily skin,
                    sensitive skin and thin skin. It is also wonderful if you
                    love your original brows shape but tired of filling them
                    every morning with a brow pencil. Price includes
                    consultation, detailed mapping, numbing, aftercare kit. A
                    touch up session must be booked up 6-8 weeks from the
                    initial service date . The price does not include touch up
                    session. Financing is available.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Brow Tattoo Removal{" "}
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $150.00 per session, 2 hours
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    If you happen to have the wrong color permanent make up or
                    wrong shape o, we are here to help correct that by using a
                    safe hypertonic saline solution that is chemical free and
                    contains non toxic ingredients that can damage the skin's
                    natural barrier or affect the melanin production. Note: more
                    that one session might be needed.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    Permanent Make up Touch up{" "}
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $150.00, 90 Minutes
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    This is a mandatory session that come 6-8 weeks after the
                    initial Micro pigmentation session. This service is critical
                    to add any needed touches after your initial treatment. We
                    examine the healing and the color saturation and give you
                    the perfect final look. Note: this service for our clients
                    who had their initial session done at our clinic.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-4 my-1">
              <div
                className="card h-100"
                style={{ backgroundColor: "rgb(228, 214, 228,0.8)" }}
              >
                <div className="card-body">
                  <h4
                    className="card-title text-center"
                    style={{ fontWeight: "bolder" }}
                  >
                    PMU Color Boost / Correction
                  </h4>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    $270.00, 2 hours
                  </h6>
                  <h6
                    className="card-subtitle text-center mb-2 mt-1"
                    style={{ fontWeight: "bolder" }}
                  >
                    <br />{" "}
                  </h6>
                  <p className="card-text" style={{ fontWeight: "bolder" }}>
                    This service is perfect choice for Clients whom brows
                    started to fade after the initial year passes. If you need
                    to either refresh the current work and add life to them or
                    if you would like to correct an oxidize blueish, reddish or
                    grayish work , this is the service for you. Note: finance is
                    available. Price does not include the touch up cost if
                    needed.
                  </p>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <ContactMe />
      <footer className="bg-light py-5 sticky-bottom">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};

export default PermanentMakeup;
