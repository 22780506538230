import React from "react";
import emailjs from "emailjs-com";
const JoinUs = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_81wicgd",
        "template_1ejnhap",
        e.target,
        "FerWYERmcW7Zx-I5i"
      )
      .then(
        (result) => {
          alert("Your Message has been Sent");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <section
        className="page-section"
        id="staff"
        style={{ backgroundImage: "linear-gradient(white,white)" }}
      >
        <div className="container" style={{ paddingBottom: "5px" }}>
          <h2 className="text-center mt-0">Our Newsletter</h2>
          <h5 className="text-center mt-1">Subscribe to our Newsletter and enjoy 20% off on your first service</h5>
          <hr className="divider my-4" />
          <form
            action="#"
            style={{ fontFamily: "El Messiri, sans-serif" }}
            onSubmit={sendEmail}
          >
            <div className="row mb-4">
              <div className="form-group col-md-4">
                <label
                  htmlFor="name"
                  className="label"
                  style={{
                    color: "rgb(105, 11, 147)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </label>
                <div className="form-field-icon-wrap">
                  <span className="icon ion-android-person"></span>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label
                  htmlFor="email"
                  className="label"
                  style={{
                    color: "rgb(105, 11, 147)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </label>
                <div className="form-field-icon-wrap">
                  <span className="icon ion-email"></span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                  />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label
                  htmlFor="phone"
                  className="label"
                  style={{
                    color: "rgb(105, 11, 147)",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Phone
                </label>
                <div className="form-field-icon-wrap">
                  <span className="icon ion-android-call"></span>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="submit"
                  className="btn btn-primary btn-outline-primary btn-block"
                  value="Send Message"
                  style={{
                    backgroundColor: "rgb(105, 11, 147)",
                    color: "white",
                    border: "none",
                    fontWeight: "bolder",
                    letterSpacing: "inherit",
                    width: "200px",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
