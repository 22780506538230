import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/home.jpg";
import JoinUs from "./JoinUs";
import Services from "./Services";
import Staff from "./Staff";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <img src={logo} style={{ width: "70%" }} alt="saimaskincare" />
              <h3 className="text-uppercase font-weight-bold">
                Where Every Woman is a Star
              </h3>
            </div>
            <div className="col-lg-8 align-self-baseline">
              <div className="row mt-1">
                <div className="col">
                  <Link
                    to="/giftcard"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <button className=" btn homebtn" style={{ width: "250px" }}>
                      Gift Certificate
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Services />
      <Staff />
      <JoinUs />
      <section
        className="page-section"
        id="contact"
        style={{ backgroundImage: "linear-gradient(white, #e4d6e4)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">Let's Get In Touch!</h2>
              <hr className="divider my-4" />
              <p className=" mb-5">
                Give us a call or send us an email and we will get back to you
                as soon as possible!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
              <div>1-571-249-2969</div>
            </div>
            <div className="col-lg-4 mr-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
              <a
                className="d-block"
                href="mailto:contact@inquiries@surayabeauty.com"
                style={{ color: "#690b93" }}
              >
                inquiries@surayabeauty.com
              </a>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <i className="fas fa-map-pin fa-3x mb-3 text-muted"></i>
              <a
                style={{ color: "#690b93" }}
                className="d-block"
                target="blank"
                href="https://goo.gl/maps/7szZj84DFXJ2UdR27"
              >
                Visit Us
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer
        className="bg-light py-5"
        style={{ backgroundImage: "linear-gradient(#e4d6e4, #e4d6e4)" }}
      >
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © 2022 - Saraya Beauty Lounge
          </div>
        </div>
      </footer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

export default connect(mapStateToProps)(Home);
