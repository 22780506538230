import React from "react";

const Policies = () => {
  return (
    <>
      <div
        className="modal fade"
        id="policies"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="policies"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="PoliciesTitle"
                style={{ color: "black", fontWeight: "bolder" }}
              >
                Lounge Etiquette and Policies
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                <li className="list-group-item">
                  1- Please arrive 15 minutes early for your appointment.
                </li>
                <li className="list-group-item">
                  2- Our services are not intended to diagnose, prevent, or
                  treat any medical or skin care condition, and you should
                  consult your doctor if you are experiencing any medical or
                  skin care concern.
                </li>
                <li className="list-group-item">
                  3- It is important to inform us ahead of time of any medical
                  condition or any needed arrangements for disabilities or
                  special needs.
                </li>
                <li className="list-group-item">
                  4- We offer a VIP saving program. For more details, look for
                  The Stars Ritual Program section.
                </li>
                <li className="list-group-item">
                  5- A credit card on file is required to reserve all
                  appointments.
                </li>
                <li className="list-group-item">
                  6- 24 hours notice is required for the cancelation or
                  rescheduling of 60 minute service or less. Over 60 minutes of
                  service/s requires a 48 hours notice.
                </li>
                <li className="list-group-item">
                  7- The minimum required age for our clients is 11 years old.
                  Any client less than 16 years old must be accompanied by a
                  legal guardian at all times during the service.
                </li>
                <li className="list-group-item">
                  8- Gift certificates are non-refundable, cannot be redeemed
                  for cash, and are valid for 12 months from the purchase date.
                </li>
                <li className="list-group-item">
                  9- Although tips are appreciated but not expected, the
                  recommended industry standard is 15-20% of the service cost.
                  Gratuities are accepted in cash, check, credit card, and
                  Venmo.
                </li>
                <li className="list-group-item">
                  10- Treatment time includes 10 minutes for dressing and
                  consultation. A 60-minute session includes 50 minutes of
                  hands-on service and 10 minutes for consultation and dressing.
                  A 90-minute session includes 80 minutes of hands-on service
                  and 10 minutes for consultation and dressing. Hair removal
                  services are not restricted by time.
                </li>
                <li className="list-group-item">
                  11- Prices are subject to change. Rates and services may vary
                  based on professional products prices and promotional
                  seasonality.
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policies;
