import React from "react";
import { Link } from "react-router-dom";
import skincare from "../../assets/images/skincare.jpg";
import touch from "../../assets/images/touch.jpg";
import trooming from "../../assets/images/trooming.jpg";
import permakeup from "../../assets/images/permakeup.jpg";

const Services = () => {
  return (
    <>
      <section
        className="page-section"
        id="services"
        style={{ backgroundImage: "linear-gradient(white, #e4d6e4)",padding:"4rem 0" }}
      >
        <div className="container">
          <h2 className="text-center mt-0">Services</h2>
          <hr className="divider my-4" />
          <div className="row mt-5">
            <div className="col-md-3 my-1 text-center">
              <Link to="/skincare" style={{ textDecoration: "none" }}>
                <img
                  className="card-img-top"
                  src={skincare}
                  alt="skincare"
                  style={{ height: "200px",width:"200px",borderRadius:"50%" ,boxShadow: "rgb(105, 11, 147) 0px 8px 20px -8px"}}
                />
              </Link>
              <h3 style={{ fontWeight: "bold" }}>Skin care</h3>
              <Link to="/skincare" style={{ textDecoration: "none" }}>
                <h6 style={{color:"rgb(60, 72, 88)"}}>Read more...</h6>
              </Link>
            </div>
            <div className="col-md-3 my-1 text-center">
              <Link to="/groominglounge" style={{ textDecoration: "none" }}>
                <img
                  className="card-img-top "
                  src={trooming}
                  alt="groominglounge"
                  style={{ height: "200px" ,width:"200px",borderRadius:"50%" ,boxShadow: "rgb(105, 11, 147) 0px 8px 20px -8px"}}
                />
              </Link>
              <h3 style={{ fontWeight: "bold" }}> The Grooming Lounge</h3>
              <Link to="/groominglounge" style={{ textDecoration: "none" }}>
                <h6 style={{color:"rgb(60, 72, 88)"}}>Read more...</h6>
              </Link>
            </div>
            <div className="col-md-3 my-1 text-center">
              <Link to="/touchtherapy" style={{ textDecoration: "none" }}>
                <img
                  className="card-img-top "
                  src={touch}
                  alt="touchtherapy"
                  style={{ height: "200px" ,width:"200px",borderRadius:"50%" ,boxShadow: "rgb(105, 11, 147) 0px 8px 20px -8px"}}
                />
              </Link>
              <h3 style={{ fontWeight: "bold" }}>Touch Therapy</h3>
              <Link to="/touchtherapy" style={{ textDecoration: "none" }}>
                <h6 style={{color:"rgb(60, 72, 88)"}}>Read more...</h6>
              </Link>
            </div>
            <div className="col-md-3 my-1 text-center">
              <Link to="/permanentmakeup" style={{ textDecoration: "none" }}>
                <img
                  className="card-img-top "
                  src={permakeup}
                  alt="permakeup"
                  style={{ height: "200px",width:"200px",borderRadius:"50%" ,boxShadow: "rgb(105, 11, 147) 0px 8px 20px -8px" }}
                />  
              </Link>
              <h3 style={{ fontWeight: "bold" }}>Permanent Makeup</h3>
              <Link to="/permanentmakeup" style={{ textDecoration: "none" }}>
                <h6 style={{color:"rgb(60, 72, 88)"}}>Read more...</h6>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
